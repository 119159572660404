import React from "react";
import { useQuery } from "react-query";
import { getAllergies, getDiets } from "../../APIs/functions";
import { Tabs } from "antd";
import "./index.scss";
import Diets from "../../Components/DietsPrefTab";
import Allergies from "../../Components/AllergyPrefTab";
import NutriTab from "../../Components/NutritionPrefTab";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { handleSetAllergyEvent, handleSetDietEvent, handleSetEnvironmentValueEvent, handleSetFoodSafetyValueEvent, handleSetNutritionValueEvent, handleSetProcessingValueEvent, handleSetValueEvent } from "../../Gtag";

const Preference = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const returnUrl = searchParams.get("returnUrl");
  const [currentTab, setCurrentTab] = useState("1");
  const [preferences, setPreferences] = useState(() => {
    // Initialize preferences from local storage, deduplicating diets and allergies
    const storedPreferences = JSON.parse(localStorage.getItem("preferences"));
    if (storedPreferences) {
      const deduplicatedDiets = [...new Set(storedPreferences.diets)];
      const deduplicatedAllergies = [...new Set(storedPreferences.allergies)];

      return {
        ...storedPreferences,
        diets: deduplicatedDiets,
        allergies: deduplicatedAllergies,
      };
    }

    return {
      diets: [],
      allergies: [],
      selectedEnvironment: 0,
      selectedFoodSafety: 0,
      selectedNutrition: 0,
      selectedProcessing: 0,
    };
  });


  const {
    data: allDiets,
    isLoading: dietsLoading,
    isError: dietsError,
  } = useQuery("allDiets", getDiets);

  const {
    data: allAllergy,
    isLoading: allergyLoading,
    isError: allergyError,
  } = useQuery("allAllergies", getAllergies);

  const onChange = (key) => {
    setCurrentTab(key);
  };

  const updatePreferences = (category, item) => {
    // Check if the category is "selectedEnvironment", "selectedNutrition", etc.
    if (category.startsWith("selected")) {
      setPreferences((prevPreferences) => ({
        ...prevPreferences,
        [category]: item,
      }));
    } else {
      // For other categories like "diets" and "allergies"
      setPreferences((prevPreferences) => {
        let updatedCategory;
        const isItemSelected = prevPreferences[category].filter(obj => obj.id === item.id)
        if (isItemSelected.length > 0) {
          // Remove the item if it's already selected
          updatedCategory = prevPreferences[category].filter(
            (preference) => preference.id !== item.id
          );
        } else {
          // Add the item if it's not selected
          updatedCategory = [...new Set([...prevPreferences[category], item])];
          // Using Set to ensure uniqueness and then converting back to an array
        }
        return {
          ...prevPreferences,
          [category]: updatedCategory,
        };
      });
    }
  };

  const items = [
    {
      key: "1",
      label: "Diets",
      children: (
        <Diets
          allDiets={allDiets}
          loading={dietsLoading}
          diets={preferences.diets}
          updateDiets={(item) => updatePreferences("diets", item)}
        />
      ),
    },
    {
      key: "2",
      label: "Allergies",
      children: (
        <Allergies
          allAllergies={allAllergy}
          loading={allergyLoading}
          allergies={preferences.allergies}
          updateAllergies={(item) => updatePreferences("allergies", item)}
        />
      ),
    },
    {
      key: "3",
      label: "Values",
      children: (
        <NutriTab
          selectedEnvironment={preferences.selectedEnvironment}
          selectedNutrition={preferences.selectedNutrition}
          selectedProcessing={preferences.selectedProcessing}
          selectedFoodSafety={preferences.selectedFoodSafety}
          onSelectValue={(category, value) =>
            updatePreferences(category, value)
          }
        />
      ),
    },
  ];

  const tabKeyRenderer = (key) => {
    switch (key) {
      case "1":
        return "Next";

      case "2":
        const check =
          preferences.allergies.length > 0 ? "Next" : "No Allergies";
        return check;

      case "3":
        return "Apply";

      default:
        return "Next";
    }
  };

  const handleClick = () => {
    if (currentTab < items.length) {
      setCurrentTab(String(Number(currentTab) + 1));
    }
    if (currentTab === "3") {
      toast.success("SAUCE! Changes saved", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      localStorage.setItem("preferences", JSON.stringify(preferences));
      handleSetDietEvent(preferences?.diets)
      handleSetAllergyEvent(preferences?.allergies)
      handleSetNutritionValueEvent(preferences?.selectedNutrition)
      handleSetFoodSafetyValueEvent(preferences?.selectedFoodSafety)
      handleSetEnvironmentValueEvent(preferences?.selectedEnvironment)
      handleSetProcessingValueEvent(preferences?.selectedFoodSafety)
      window.location.href=returnUrl
    }
  };

  return (
    <section className="preference-setup-wrap">
        <Tabs
          className="w-100"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          activeKey={currentTab}
        />
        <div className="pref-tab-handler">
          <button onClick={handleClick}>{tabKeyRenderer(currentTab)}</button>
        </div>
    </section>
  );
};

export default Preference;
