export const handleFaqClick = (question) => {
  window.gtag("event", "faq_click", {
    event_label: "FAQ Question",
    event_value: question,
  });
};

export const handleRadialClick = (productName) => {
  window.gtag("event", "main_gsRadial_click", {
    event_label: "PDP-GS-RADIAL",
    event_value: productName,
  });
};

export const handleProductCardClick = (carousel, productName) => {
  window.gtag("event", "product_card_click", {
    event_label: "Product Cards",
    event_value: `${carousel} ${productName}`,
  });
};

export const handleSlideTrack = (productName) => {
  window.gtag("event", "pdp_image_slide", {
    event_label: "PDP Banner Image Slider",
    event_value: productName,
  });
};

export const handleTabsClicked = (tabname) => {
  window.gtag("event", "pdp_tabs_clicked", {
    event_label: "PDP Tabs Clicked",
    event_value: tabname,
  });
};

export const handleDisclaimerClicked = () => {
  window.gtag("event", "disclaimer_cta_clicked", {
    event_label: "Disclaimer",
    event_value: "Disclaimer CTA click",
  });
};

export const handleReportClicked = (product) => {
  window.gtag("event", "report_cta_clicked", {
    event_label: "Bug Report",
    event_value: `Report CTA click for ${product}`,
  });
};

export const handleBetterAlternatives = () => {
  window.gtag("event", "better_alternatives_slide", {
    event_label: "Better-for-you alternatives",
    event_value: "Better alternative carousel",
  });
};

export const handleSimilarProducts = () => {
  window.gtag("event", "product_pairings_slide", {
    event_label: "Product pairings",
    event_value: "Product pairings carousel",
  });
};

export const handleProcessingGSClicked = () => {
  window.gtag("event", "processing_gs_clicked", {
    event_label: "PDP GS CHART",
    event_value: "Processing GS click",
  });
};

export const handleNutritionGSClicked = () => {
  window.gtag("event", "nutrition_gs_clicked", {
    event_label: "PDP GS CHART",
    event_value: "Nutrition GS click",
  });
};

export const handleFoodSafetyGSClicked = () => {
  window.gtag("event", "food_safety_gs_clicked", {
    event_label: "PDP GS CHART",
    event_value: "Food safety GS click",
  });
};

export const handleEnvironmentGSClicked = () => {
  window.gtag("event", "environment_gs_clicked", {
    event_label: "PDP GS CHART",
    event_value: "Environment GS click",
  });
};

export const handleDailyValueModal = () => {
  window.gtag("event", "gs_daily_value_modal_clicked", {
    event_label: "PDP GS CHART",
    event_value: "GS daily value modal clicked",
  });
}


export const handleFoodSafetyModal = () => {
  window.gtag("event", "gs_food_safety_modal_clicked", {
    event_label: "PDP GS CHART",
    event_value: "GS food safety modal clicked",
  });
}

export const handleProcessingModal = () => {
  window.gtag("event", "gs_processing_modal_clicked", {
    event_label: "PDP GS CHART",
    event_value: "GS processing modal clicked",
  });
}

export const handleEnvironmentModal = () => {
  window.gtag("event", "gs_environment_modal_clicked", {
    event_label: "PDP GS CHART",
    event_value: "GS environment footprint modal clicked",
  });
}

export const handleEncourageNutrition = (nutrient) => {
  window.gtag("event", "gs_encourage_nutrient_clicked", {
    event_label: "GS Chart nutrition",
    event_value: `Encourage ${nutrient}`,
  });
}

export const handleLimitNutrition = (nutrient) => {
  window.gtag("event", "gs_limit_nutrient_clicked", {
    event_label: "GS Chart nutrition",
    event_value: `Limit ${nutrient}`,
  });
}

export const handleCreateProfile = () => {
  window.gtag("event", "create_profile_clicked", {
    event_label: "Create My Dietary Profile",
    event_value: `${JSON.parse(localStorage.getItem("userId"))}`,
  });
}

export const handleSetDietEvent = (diets) => {
  const res = diets.map(item => item?.diet).join(',')
  window.gtag("event", "user_diets", {
    diets: res,
    user: `${JSON.parse(localStorage.getItem("userId"))}`
  });
};

export const handleSetAllergyEvent = (allergies) => {
  const res = allergies.map(item => item?.allergy).join(',')
  window.gtag("event", "user_allergies", {
    allergies: res,
    user: `${JSON.parse(localStorage.getItem("userId"))}`
  });
};

export const handleSetNutritionValueEvent = (value) => {
  window.gtag("event", "user_nutrition_value", {
    nutrition_value: value,
    user: `${JSON.parse(localStorage.getItem("userId"))}`
  });
};

export const handleSetFoodSafetyValueEvent = (value) => {
  window.gtag("event", "user_food_safety_value", {
    food_safety_value: value,
    user: `${JSON.parse(localStorage.getItem("userId"))}`
  });
};

export const handleSetEnvironmentValueEvent = (value) => {
  window.gtag("event", "user_environment_value", {
    environment_value: value,
    user: `${JSON.parse(localStorage.getItem("userId"))}`
  });
};

export const handleSetProcessingValueEvent = (value) => {
  window.gtag("event", "user_processing_value", {
    processing_value: value,
    user: `${JSON.parse(localStorage.getItem("userId"))}`
  });
};
