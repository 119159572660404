import React from "react";
import './index.scss'

const NutriTab = ({
  selectedEnvironment,
  selectedNutrition,
  selectedProcessing,
  selectedFoodSafety,
  onSelectValue
}) => {
  const renderPriorityButtons = (category , selectedValue) => {
    const buttons = [];
    for (let i = 1; i <= 10; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => onSelectValue(category, i)}
          type="button"
          className={`${selectedValue === i ? "btn selected" : "btn"}`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className="nutri-tabs-wrap">
      <h2 className="text-title">What do you care about? Help us so we can help you</h2>
      <div>
        {[
          { category: "Nutrition", text: "Maintain a healthy balanced diet", selectedValue: selectedNutrition},
          { category: "Processing", text: "Less artificial ingredients and additives", selectedValue: selectedProcessing},
          { category: "FoodSafety", text: "Avoid harmful chemicals and toxic additives", selectedValue: selectedFoodSafety},
          { category: "Environment", text: "Fight climate change. Avoid products that harm the planet.", selectedValue: selectedEnvironment},
        ].map((item, index) => (
          <div key={index} className="value-group pb-5 pt-2">
            <div className="nutrition-text mb-2">{item.category}</div>
            <div className="balanced-text">{item.text}</div>
            <div className="priority-digit d-flex justify-content-between my-2">
              {renderPriorityButtons(`selected${item.category}` , item.selectedValue)}
            </div>
            <div className="d-flex justify-content-between px-2">
              <div className="balanced-text">Not a priority</div>
              <div className="balanced-text">Very important</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NutriTab;
