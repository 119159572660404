import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { similarProducts } from "../../APIs/functions";
import ProductCarousel from "../ProductCarousel";
import "./index.scss";
import ClientProductCarousel from "../ClientProductCarousel";
import { Spin } from "antd";

const ProductPairing = ({ product }) => {
  const { productNameURL, productName } = product;
  const url = new URL(window.location.href);
  const userLocation = url.searchParams.get("location");
  const queries = {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    cacheTime: 325000,
    staleTime: 300000,
  };

  const hostnameParts = url.hostname.split('.');
  const client = hostnameParts.length > 2 ? hostnameParts.slice(0, hostnameParts.length - 2).join('.') : '';

  // const [isRefferer, setIsRefferer] = useState(false);

  // useEffect(() => {
  //   const referringSite = document.referrer;
  //   if (referringSite.includes('https://marquette.storebyweb.com/')) {
  //     setIsRefferer(true);
  //   }
  // }, []);

  const preferences = JSON.parse(localStorage.getItem('preferences')) || {};

  const {
    data: pairProducts,
    isLoading: pairProductsLoading,
    isError: pairProductsError,
  } = useQuery(
    ["pairProducts", productNameURL, userLocation],
    () => similarProducts({ productNameURL, client, userLocation, preferences }),
    queries
  );

  const products = (!pairProductsLoading && !pairProductsError && Array.isArray(pairProducts)) ? pairProducts : (Array.isArray(pairProducts?.data) ? pairProducts.data : []);


  const productWithImages = products && products.length > 0 ? products?.filter(item => item?.mainProductImage?.indexOf("images-greenchoice-io.nyc3.cdn.digitaloceanspaces.com") > 1) : []


  return (
    <>
      {!pairProductsLoading && productWithImages?.length > 0 && (
        <section id="productPairing">
          <div className="container overflow-hidden">
            <div className="text-center alternative-title">
              <h2> Product pairings available at this store </h2>
            </div>
            {productWithImages !== null && <ProductCarousel
              carousel={"similar"}
              items={productWithImages}
            />}
            {/* {!isRefferer ? productWithImages !== null && (
              <ProductCarousel
                carousel={"similar"}
                items={productWithImages}
              />
            ) : <ClientProductCarousel
              carousel={"similar"}
              items={sortedProducts}
            />} */}
          </div>
        </section>
      )}
    </>
  );
};

export default ProductPairing;
