import React, { useEffect } from "react";
import logo from "../../Assets/Images/poweredLogo.svg";
import { useContext } from "react";
import { NameContext } from "../../Context/NameContext";
import { Link, useLocation } from "react-router-dom";
import navbarToggler from "../../Assets/Images/navToggler.svg";
import { storesArr } from "../../Utils/Constants";
import "./index.scss";

const Header = () => {
  const url = new URL(window.location.href);
  const hostnameParts = url.hostname.split('.');
  const store = hostnameParts.length > 2 ? hostnameParts.slice(0, hostnameParts.length - 2).join('.') : '';
  const currentLocation = useLocation();
  const returnUrl = currentLocation.pathname + currentLocation.search;

  const { setName } = useContext(NameContext);

  useEffect(() => {
    const getFullName = () => {
      const storeObject = storesArr.find((item) => item.name === store);
      if (storeObject) {
        setName(storeObject.fullName);
      } else {
        setName(store)
      }
    };
    getFullName()
  }, [setName, store]);

  const closeSideBar = () => {
    const el = document.querySelector('#navbarSupportedContent')
    el.classList.remove('show')
  }

  return (
    <header className="header">
      <nav className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-4">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <img src={navbarToggler} alt="navbarToggler" className="img-fluid" />
            </button>
          </div>
          <div className="col-4 text-center">
            <img
              className="store-logo pe-2 me-1"
              src={storesArr.some((item) => item.name === store) ? `https://store-images-gc.nyc3.cdn.digitaloceanspaces.com/${store}.png` : "/images/placeholder-logo.png"}
              alt="store-logo"
            />
          </div>
          <div className="col-4 text-end">
            <img loading="lazy" className="powered-by-gc" src={logo} alt="greenchoice-logo" />
          </div>


        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="d-flex justify-content-end ">
            <button onClick={closeSideBar} className="close-btn">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none"><path d="M15.7108 17.744L15.3572 17.3905L15.0037 17.744L10.2703 22.4774C9.69357 23.0541 8.75854 23.0541 8.18184 22.4774C7.60513 21.9007 7.60513 20.9657 8.18184 20.389L12.9152 15.6556L13.2688 15.302L12.9152 14.9485L8.21361 10.2469C7.67199 9.70525 7.67199 8.82711 8.21361 8.28549C8.75522 7.74387 9.63336 7.74387 10.175 8.28549L14.8766 12.9871L15.2301 13.3407L15.5837 12.9871L20.3171 8.25373C20.8938 7.67702 21.8288 7.67702 22.4055 8.25373C22.9822 8.83043 22.9822 9.76546 22.4055 10.3422L17.6721 15.0755L17.3186 15.4291L17.6721 15.7826L22.4373 20.5478C22.9789 21.0894 22.9789 21.9675 22.4373 22.5092C21.8957 23.0508 21.0175 23.0508 20.4759 22.5092L15.7108 17.744Z" fill="#6B9080" stroke="white"></path></svg>
            </button>
          </div>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li onClick={closeSideBar} className="nav-item">
              <Link to={`/preferences-setup?returnUrl=${encodeURIComponent(returnUrl)}`} className="nav-link"> My Preferences </Link>
            </li>
            <li onClick={closeSideBar} className="nav-item">
              <Link to="/greenscore" className="nav-link"> GreenScore® Standards </Link>
            </li>

            <li onClick={closeSideBar} className="nav-item">
              <Link to="/glossary" className="nav-link"> Diet & Values Tag Definitions </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
