import { Spin } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { router } from './Routes';
import './App.css';
import { NameProvider } from './Context/NameContext';
import { storesArr } from './Utils/Constants';
import DisabledPage from './Pages/DisabledPage';

function App() {
  const url = new URL(window.location.href);
  const hostnameParts = url.hostname.split('.');
  const store = hostnameParts.length > 2 ? hostnameParts.slice(0, hostnameParts.length - 2).join('.') : '';
  const storeObject = storesArr.find((item) => item.name === store);

  return (
    <>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
      />
      {storeObject?.status === "disabled"
        ? <DisabledPage />
        : <NameProvider>
          <RouterProvider
            router={router}
            fallbackElement={<Spin fullscreen={false} delay={500} size="small" tip="Researching..." />}
          />
        </NameProvider>}
    </>
  );
}

export default App;
