import React from 'react'
import './index.scss'

const Diets = ({allDiets , loading, diets, updateDiets}) => {

  const handleClick = (item) => {
    updateDiets(item)
  };

  return (
    <div className='diets-tab-wrap'>
        <h2 className='text-title'>How do you want to eat?</h2>
        <div className="diets-button-holder">
            {!loading && allDiets && allDiets.map((item,idx) => {
                const isDietSelected = diets.some((diet) => diet.id === item.id);
                const buttonClass = isDietSelected ? 'btn selected' : 'btn';
                return <div key={item?.id}>
                    <button className={buttonClass} onClick={() => handleClick(item)}>
                        {item?.diet}
                    </button>
                </div>
            })}
        </div>
    </div>
  )
}

export default Diets