import React from 'react'
import './index.scss'
const Allergies = ({allAllergies, loading, allergies, updateAllergies}) => {

  const handleClick = (item) => {
    updateAllergies(item)
  }

  return (
     <div className='allergies-tab-wrap'>
        <h2 className='text-title'> Do you have food allergies or sensitivities?  </h2>
        <div className="allergies-button-holder">
            {!loading && allAllergies && allAllergies.map((item,idx) => {
                const isSelected = allergies.some((allergy) => allergy.id === item.id);
                const buttonClass = isSelected ? 'btn selected' : 'btn';
                return <div key={item?.id}>
                    <button className={buttonClass} onClick={() => handleClick(item)}>
                        {item?.allergy}
                    </button>
                </div>
            })}
        </div>
    </div>
  )
}

export default Allergies